// Document Ready
jQuery(document).ready(function($) {
	$('html').restive({
		breakpoints: ['575' , '767' , '991' , '1199', '10000'],
		classes: ['bootstrap-xs bootstrap_sm bootstrap_md bootstrap_lg bootstrap_xl' , 'bootstrap-sm bootstrap_md bootstrap_lg bootstrap_xl' , 'bootstrap-md bootstrap_lg bootstrap_xl' , 'bootstrap-lg bootstrap_xl', 'bootstrap-xl'],
		force_dip: true,
		turbo_classes: 'is_landscape=landscape'
	});
	$('header').restive({
		breakpoints: ['575' , '767' , '991' , '1199', '10000'],
		classes: ['bootstrap-xs bootstrap_sm bootstrap_md bootstrap_lg bootstrap_xl' , 'bootstrap-sm bootstrap_md bootstrap_lg bootstrap_xl' , 'bootstrap-md bootstrap_lg bootstrap_xl' , 'bootstrap-lg bootstrap_xl', 'bootstrap-xl'],
		force_dip: true,
		turbo_classes: 'is_landscape=landscape'
	});
	$('main').restive({
		breakpoints: ['575' , '767' , '991' , '1199', '10000'],
		classes: ['bootstrap-xs bootstrap_sm bootstrap_md bootstrap_lg bootstrap_xl' , 'bootstrap-sm bootstrap_md bootstrap_lg bootstrap_xl' , 'bootstrap-md bootstrap_lg bootstrap_xl' , 'bootstrap-lg bootstrap_xl', 'bootstrap-xl'],
		force_dip: true,
		turbo_classes: 'is_landscape=landscape'
	});
	$('aside').restive({
		breakpoints: ['575' , '767' , '991' , '1199', '10000'],
		classes: ['bootstrap-xs bootstrap_sm bootstrap_md bootstrap_lg bootstrap_xl' , 'bootstrap-sm bootstrap_md bootstrap_lg bootstrap_xl' , 'bootstrap-md bootstrap_lg bootstrap_xl' , 'bootstrap-lg bootstrap_xl', 'bootstrap-xl'],
		force_dip: true,
	   turbo_classes: 'is_landscape=landscape'
	});
	$('footer').restive({
		breakpoints: ['575' , '767' , '991' , '1199', '10000'],
		classes: ['bootstrap-xs bootstrap_sm bootstrap_md bootstrap_lg bootstrap_xl' , 'bootstrap-sm bootstrap_md bootstrap_lg bootstrap_xl' , 'bootstrap-md bootstrap_lg bootstrap_xl' , 'bootstrap-lg bootstrap_xl', 'bootstrap-xl'],
		force_dip: true,
		turbo_classes: 'is_landscape=landscape'
	});
	var $formsActive = false;
// 	$url = new Url('index.php/fahrzeugverkauf?price=&age=&brand=&category=&condition=&fuel=');
	$('.dokument').on('click', function(e) {
		e.preventDefault();
		$(this).next('.aridoc-container').toggleClass('open');
	})
	$('.navigation.bottom a').on('touchstart click', function () {
        var $target = $(this.hash);
        $target = $target.length && $target || $('[name=' + this.hash.slice(1) +']');
        if ($target.length) {
            var targetOffset = $target.offset().top - 150;
            $('html,body').animate({scrollTop: targetOffset}, 1000);
            return false;
        }
    });
	$('aside .filter-mobile-de').niceScroll({
		cursorcolor: "rgba(255,255,255,0.75)",
		cursorwidth: "4px",
		cursorborder: "1px solid transparent"
	});
	$('header.bootstrap_lg ul.nav-child').niceScroll({
		cursorcolor: "rgba(255,255,255,0.75)",
		cursorwidth: "4px",
		cursorborder: "1px solid transparent"
	});
	$('.flexslider div[class*="bg-"]').niceScroll({
		cursorcolor: "transparent",
		cursorborder: "1px solid transparent"
	});
	$('aside .vpmError').niceScroll({
		cursorcolor: "rgba(255,255,255,0.75)",
		cursorwidth: "4px",
		cursorborder: "1px solid transparent"
	});
	// @note Formulare per form-selector öffnen/schließen
	$('.form-selector').on('click', function(event) {
		var $this = $(this),
		$body = $('body');
		hideFormErrors();
		hideVehicleSearchFields();
		if (event.target.getAttribute('data-form-name') == 'allgemeiner_kontakt') {
			let $toShow = [
				'general_',
				'ak_'
			];
			showAndHideFormSections($toShow);
			$('.general_previous_step').hide();
			showAndHideFormHeadings('ak_');
			ff_switchpage(1);
		}
		if (event.target.getAttribute('data-form-name') == 'anfrage_vermietung') {
			let $toShow = [
				'general_',
				'vm_'
			];
			showAndHideFormSections($toShow);
			showAndHideFormHeadings('vm_');
			ff_getElementByName('general_recipient').value = "vermietung@neuseenlandcaravaning.de";
			ff_switchpage(1);
		}
		if (event.target.getAttribute('data-form-name') == 'werkstatt_termin') {
			let $toShow = [
				'general_',
				'ws_'
			];
			showAndHideFormSections($toShow);
			showAndHideFormHeadings('ws_');
			ff_getElementByName('general_recipient').value = "info@neuseenlandcaravaning.de";
			ff_switchpage(1);
		}
		if (event.target.getAttribute('data-form-name') == 'fahrzeug_suchen') {
			$('.filter-mobile-de').show();
			let $toShow = [];
			showAndHideFormSections($toShow);
			showAndHideFormHeadings([]);
			document.querySelectorAll('.bfFormDiv .general_info_required_fields').forEach(($p) => {
				$p.hide();
			});
		}

		if ($this.hasClass('active')) {
			$body.removeClass('form-open');
			$this.removeClass('active');
		} else {
			$body.addClass('form-open');
			$this.addClass('active');
			$this.siblings().removeClass('active');
		}
		// ff_switchpage($formPage);
		// if ($formPage == 999) {
			// } else {
				
				// }
	});

	// @note Formulare per Hamburger-Menü oder Text-Links in Beiträgen öffnen/schließen
	$('.formular').on('click', function (e) {
		e.preventDefault();
		var $this = $(this);
		hideVehicleSearchFields();
		if ($this.hasClass('allgemein')) {
			let $toShow = [
				'ak_',
				'general_'
			];
			showAndHideFormSections($toShow);
			showAndHideFormHeadings('ak_');
			$('.general_previous_step').hide();
			ff_switchpage(1);
		}
		if ($this.hasClass('vermietung')) {
			let $toShow = [
				'vm_',
				'general_'
			];
			showAndHideFormSections($toShow);
			showAndHideFormHeadings('vm_');
			ff_getElementByName('general_recipient').value = "vermietung@neuseenlandcaravaning.de";
			ff_switchpage(1);
		}
		if ($this.hasClass('werkstatt')) {
			let $toShow = [
				'ws_',
				'general_'
			];
			showAndHideFormSections($toShow);
			showAndHideFormHeadings('ws_');
			ff_getElementByName('general_recipient').value = "info@neuseenlandcaravaning.de";
			ff_switchpage(1);
		}
		if ($this.hasClass('fahrzeugsuche')) {
			$('.filter-mobile-de').show();
			let $toShow = [];
			showAndHideFormSections($toShow);
			showAndHideFormHeadings([]);
			document.querySelectorAll('.bfFormDiv .general_info_required_fields').forEach(($p) => {
				$p.hide();
			});
		}
		
	});

	$('button[name*="_cancel"]').on('click', function(event) {
		document.querySelector('body').removeClass('form-open');
		$('.form-selector').removeClass('active');
	});
	//!| HAUPTMENÜ
	//!↳ Hamburger-Menü öffnen/schließen
	$('header .deeper.parent span.nav-header').on('click', function() {
		$(this).nextAll('ul').toggleClass('opened');
		if ($(this).nextAll('ul').hasClass('opened')) {
			$formsActive = true;
		} else {
			$formsActive = false;
		}
	})
	
	//!| KONTAKTFORMULARE
	//!↳ Formulare per Klick auf 'X' schließen
	$('.close-form').on('click', function() {
		var $body = $('body');
		$body.removeClass('form-open');
		$('.form-selector').removeClass('hidden');
		$('.frankana-freiko-kataloge').removeClass('open');
	})
	//!↳ VERMIETUNG
	//  !↳➝ Select Liste 'Fahrzeugkategorie' für 'Vermietung' befüllen
	if ($('body').hasClass('mietfahrzeuge')) {
		var $select = $('select[name*="vm_vehicle_category"]')[0],
		$counter = 0;
		// Vorhandene Werte aus select löschen
		for (i = $select.options.length - 1 ; i >= 0 ; i--) {
		  $select.remove(i);
		}
		// Neue Einträge basierend auf den verfügbaren Fahrzeugkategorien zu select hinzufügen
		var $option = document.createElement("option");
		$option.text = "-";
		$option.value = "-1";
		$select.add($option, $counter);
		$('.mietfahrzeug').each(function() {
			var $option = document.createElement("option");
			$counter++;
			$option.text = $(this).find('.fahrzeug-infos .kategorie h3').text();
			$option.value = $(this).find('.fahrzeug-infos .kategorie h3').text();
			$select.add($option, $counter);
		})
	}
	//!↳ CAMPINGARTIKEL
	//!↳➝ Weiteren Artikel bestellen
	$('input[name*="weiteren_artikel_bestellen_"]').on('click', function () {
		$('.formError').hide();
		var $this = $(this),
		$naechsteZeile,
		$zeilenIndex;
		$zeilenIndex = parseInt($this.attr('name').substr($this.attr('name').length - 4, 2));
		if ($zeilenIndex < 9 ) {
			$naechsteZeile = 'zeile_0' + ($zeilenIndex + 1);
		} else {
			$naechsteZeile = 'zeile_' + ($zeilenIndex + 1);
		}
		bfToggleFields('on','section',$naechsteZeile,bfDeactivateField);
	});
	//!↳➝ Artikel löschen
	$('input[name*="artikel_loeschen_"]').on('click', function () {
		var $this = $(this),
		$aktuelleZeile,
		$zeilenIndex;
		$zeilenIndex = parseInt($this.attr('name').substr($this.attr('name').length - 4, 2));
		if ($zeilenIndex < 10 ) {
			$aktuelleZeile = 'zeile_0' + $zeilenIndex;
		} else {
			$aktuelleZeile = 'zeile_' + $zeilenIndex;
		}
		bfToggleFields('off','section',$aktuelleZeile,bfDeactivateField);
	});
	//!↳ WERKSTATT
	//  !↳➝ Ein-/Ausblenden Kalender Pop-Ups abhängig von Checkbox 'Termin bitte so früh wie möglich'
	$('input[name*="date_as_soon_as_possible"]').on('click', function () {
		if ($(this).is(':checked')) {
			bfToggleFields('off','element','ws_date_from_w50',bfDeactivateField);
			bfToggleFields('off','element','ws_date_until_w50',bfDeactivateField);
		} else {
			bfToggleFields('on','element','ws_date_from_w50',bfDeactivateField);
			bfToggleFields('on','element','ws_date_until_w50',bfDeactivateField);
		}
	});
	
	//!| MAIN
	//!↳ WERSKTATT
	//  !↳➝ Terminanfrage Werkstatt öffnen
	$('button.werkstatt-termin').on('click', function() {
		$body = $('body');
		$body.addClass('form-open');
		$('.filter-mobile-de').hide();
		document.querySelectorAll('.bfFormDiv section').forEach(($section) => {
			if (!($section.id.includes('ws_') || $section.id.includes('general_'))) {
				bfToggleFields('off', 'section', $section.id, bfDeactivateField);
			} else {
				bfToggleFields('on', 'section', $section.id, bfDeactivateField);
			}
		});
		document.querySelectorAll('.bfFormDiv h2').forEach(($h2) => {
			$h2.hide();
		});
		document.querySelectorAll('.bfFormDiv h2[class*="ws_"]').forEach(($h2) => {
			$h2.show();
		});
		document.querySelectorAll('.bfFormDiv h3').forEach(($h3) => {
			$h3.hide();
		});
		document.querySelectorAll('.bfFormDiv h3[class*="ws_"]').forEach(($h3) => {
			$h3.show();
		});
		document.querySelectorAll('.bfFormDiv .general_info_required_fields').forEach(($p) => {
			$p.show();
		});
		ff_switchpage(1);
	})
	//!↳ CAMPINGARTIKEL
	//  !↳➝ Bestellformular Campingartikel öffnen
	$('.campingartikel-bestellen').on('click', function(event) {
		event.preventDefault();
		var $formPage = 4,
		$html = $('html'),
		$body = $('body'),
		$toShow = [
			'general_',
			'bc_',
			'zeile_01'
		];
		$body.addClass('form-open');
		// $('.form-selector').addClass('hidden');
		$('.filter-mobile-de').hide();
		showAndHideFormSections($toShow);
		ff_switchpage($formPage);
	});

	// @note function showAndHideFormSections
	function showAndHideFormSections($substrings) {
		document.querySelectorAll('.bfFormDiv section').forEach(($section) => {
			let $show = false;
			$substrings.forEach(($substring) => {
				if ($section.id.includes($substring)) {
					$show = true;
				}
			});
			if ($show == true) {
				bfToggleFields('on', 'section', $section.id, bfDeactivateField);
			} else {
				bfToggleFields('off', 'section', $section.id, bfDeactivateField);
			}
		});
	}

	// @note function showAndHideFormHeadings
	function showAndHideFormHeadings($substring) {
		document.querySelectorAll('.bfFormDiv h2').forEach(($h2) => {
			$h2.hide();
		});
		document.querySelectorAll('.bfFormDiv h2[class*="' + $substring + '"]').forEach(($h2) => {
			$h2.show();
		});
		document.querySelectorAll('.bfFormDiv h3').forEach(($h3) => {
			$h3.hide();
		});
		document.querySelectorAll('.bfFormDiv h3[class*="' + $substring + '"]').forEach(($h3) => {
			$h3.show();
		});
		document.querySelectorAll('.bfFormDiv .general_info_required_fields').forEach(($p) => {
			$p.show();
		});
	}

	// @note function hideFormErrors
	function hideFormErrors() {
		$('.formError').hide();
	}

	// @note function hideVehicleSearchFields
	function hideVehicleSearchFields() {
		$('.filter-mobile-de').hide();
	}
	//!↳ MIETFAHRZEUGE
	//  !↳➝ Klick auf 'Jetzt Buchen'
	$('.mietfahrzeuge .mietfahrzeug button').on('click', function() {
		var $this = $(this), 
		$body = $('body');
		hideFormErrors();
		hideVehicleSearchFields();
		// Select-Liste im Viermietformular vorausfüllen
		$selectList = $('select[name*="vm_vehicle_category"]');
		$selectList.val($this.parents('.mietfahrzeug').find('.fahrzeug-infos .kategorie h3').text());
		// Slide-In Formular 'Vermietung' öffnen
		$('body').addClass('form-open');
        let $toShow = [
            'general_',
            'vm_'
        ];
        showAndHideFormSections($toShow);
        showAndHideFormHeadings('vm_');
        ff_getElementByName('general_recipient').value = "vermietung@neuseenlandcaravaning.de";
        ff_switchpage(1);
        if ($this.hasClass('active')) {
			$body.removeClass('form-open');
			$this.removeClass('active');
		} else {
			$body.addClass('form-open');
			$this.addClass('active');
			$this.siblings().removeClass('active');
		}
		// ff_switchpage(2);
	})
	
	

	$transitionTimeSliding = Number($('aside').css("transition-duration").substr(0, $('aside').css("transition-duration").length - 1))*1000;

	// $('ZU-BEOBACHTENDES-MENÜ').ddscrollSpy({
	//})
	// Klassennamen für Viewportbreiten vergeben
	
	// SlickSlider für Partner Logos initialisieren
	$('.partner .logos').slick({
		autoplay: true,
		infinite: true,
		pauseOnHover: true,
		slidesToShow: 5,
		variableWidth: true,
		centerMode: true,
		arrows: false,
		responsive: [
		    {
		      breakpoint: 10000,
		      settings: {
		        autoplay: false
		      }
		    },
		    {
		      breakpoint: 2000,
		      settings: {
		        slidesToShow: 5,
		        autoplay: true
		      }
		    }
		  ]
	});
	//////////////////////////////////////////////
	// Slide-In Formulare (Über Radio Buttons) //
	////////////////////////////////////////////
	$('label.radio input').on('click', function(){
		$('.vpmError').html("");
		var $this = $(this).parent('label'),
		$radioValue = $(this).attr('value'),
		$radioValues = [$(this).closest('.controls').find('label.radio input').eq(0).attr('value'), $(this).closest('.controls').find('label.radio input').eq(1).attr('value'), $(this).closest('.controls').find('label.radio input').eq(2).attr('value'), $(this).closest('.controls').find('label.radio input').eq(3).attr('value')],
		$aside = $('aside'),
		$body = $('body'),
		$thankyouPage = $('#bfPage2');
		
		if (($aside).hasClass($radioValue) && ($aside).hasClass('open')) {
			// Wenn der aktive Button nochmals gedrückt wird, wird das Formular geschlossen
			$aside.removeClass('open');
			$body.removeClass('form-open');
		} else {
			// Wenn ein anderer Button gedrück wird, ändert sich die aside-Klasse
			for ($radioButton = 0; $radioButton < $radioValues.length; $radioButton++) {
				$aside.removeClass($radioValues[$radioButton]);
			}
			$aside.addClass($radioValue).addClass('open');
			$body.addClass('form-open');
		}
		$('aside section').getNiceScroll().resize();
		$('aside .filter-mobile-de').getNiceScroll().resize();
	})
	//////////////////////////////////////////
	// Slide-In Formulare (Über Hauptmenü) //
	////////////////////////////////////////
	$('.formular').on('click', function() {
			// Hauptmenü schließen
			$('.bootstrap_xl header .deeper.parent span.nav-header').nextAll('ul').removeClass('opened');
			$('.vpmError').html("");
			var $this = $(this),
			$spanClasses = $this.attr('class'),
			$aside = $('aside'),
			$body = $('body'),
			$thankyouPage = $('#bfPage2');
			if ($spanClasses.search("allgemein") > 0) {
				if (($aside).hasClass("allgemein") && ($aside).hasClass('open')) {
					$aside.removeClass('open');
					$body.removeClass('form-open');
				} else {
					$aside.removeClass('vermietung').removeClass('werkstatt').removeClass('fahrzeugsuche');
					$aside.addClass('allgemein').addClass('open');
					$body.addClass('form-open');
				}
			}
			if ($spanClasses.search("vermietung") > 0) {
				if (($aside).hasClass("vermietung") && ($aside).hasClass('open')) {
					$aside.removeClass('open');
					$body.removeClass('form-open');
				} else {
					$aside.removeClass('allgemein').removeClass('werkstatt').removeClass('fahrzeugsuche');
					$aside.addClass('vermietung').addClass('open');
					$body.addClass('form-open');
				}
			}
			if ($spanClasses.search("werkstatt") > 0) {
				if (($aside).hasClass("werkstatt") && ($aside).hasClass('open')) {
					$aside.removeClass('open');
					$body.removeClass('form-open');
				} else {
					$aside.removeClass('vermietung').removeClass('allgemein').removeClass('fahrzeugsuche');
					$aside.addClass('werkstatt').addClass('open');
					$body.addClass('form-open');
				}
			}
			if ($spanClasses.search("fahrzeugsuche") > 0) {
				if (($aside).hasClass("fahrzeugsuche") && ($aside).hasClass('open')) {
					$aside.removeClass('open');
					$body.removeClass('form-open');
				} else {
					$aside.removeClass('vermietung').removeClass('werkstatt').removeClass('allgemein');
					$aside.addClass('fahrzeugsuche').addClass('open');
					$body.addClass('form-open');
				}
			}
			$('aside section').getNiceScroll().resize();
			$('aside .filter-mobile-de').getNiceScroll().resize();
	});
	//////////////////////////////////////////
	// Slide-In Formulare (Über Textlinks) //
	////////////////////////////////////////
	$('a.open-form').on('click', function(e) {
		var $this = $(this),
		$aside = $('aside'),
		$body = $('body');
		e.preventDefault();
		$aside.removeClass('vermietung').removeClass('allgemein').removeClass('fahrzeugsuche');
		$aside.addClass('open').addClass($(this).attr('data-form-name'));
		$body.addClass('form-open');
		$('aside section').getNiceScroll().resize();
	});
	$('.fader').on('click', function() {
		var $aside = $('aside'),
		$body = $('body');
		$aside.removeClass('open');
		$body.removeClass('form-open');
	})
	$('.close-form').on('click', function() {
		var $aside = $('aside'),
		$body = $('body');
		$aside.removeClass('open');
		$body.removeClass('form-open');
	})
	//////////////////////////////////////////
	// Dankesseite //
	////////////////////////////////////////
	$('#bfPage2 div[class*="_neue_anfrage_"] button').on('click', function() {
		$('#bfPage2').hide();
		$('#bfPage1').show();
		$('.vpmError').html("");
	})
	$('.bfPage div[class*="_formular_schliessen_"] button').on('click', function() {
		var $aside = $('aside'),
		$body = $('body');
		$aside.removeClass('open');
		$body.removeClass('form-open');
	})
	/////////////////////////////
	// FAHRZEUGSUCHE MOBILE DE //
	////////////////////////////
	// Übergabge der Filterparameter an Verkaufs-URL
	$('aside button.submit').on('click', function () {
		if ($('body').hasClass('landingpage')) {
			$url = new Url("index.php/de/fahrzeugverkauf?brand=&category=&condition=#suchergebnisse");
		} else {
			$url = new Url("../../index.php/de/fahrzeugverkauf?brand=&category=&condition=#suchergebnisse");
		}
		$('aside select[class*="data-"]').each(function() {
			var $this = $(this);
			$url.query[$this.attr("name")] = $this.val();
		})
		window.location.href = $url;
	})
	// Übergabe der Filterparameter an den Fahrzeugfilter
	var $url = new Url();
	if ($('body').hasClass('fahrzeugverkauf')) {
		if ($url.query["brand"]) {
			$('.vehicle_filter select[name="brand"]').val($url.query["brand"]);
			// $('.vehicle_filter select[name="price"]').val($url.query["price"]);
			// $('.vehicle_filter select[name="age"]').val($url.query["age"]);
			$('.vehicle_filter select[name="category"]').val($url.query["category"]);
			$('.vehicle_filter select[name="condition"]').val($url.query["condition"]);
			// $('.vehicle_filter select[name="fuel"]').val($url.query["fuel"]);
			filter_results();
		} else {
			
		}
	}
	// Filterung der Ergebnisse von mobile.de
	$('.vehicle_filter select').change(function() {
		filter_results();
	});
	$('.vehicle_filter button.reset').on('click', function() {
		$('.vehicle_filter select').each(function() {
			$(this).val("-1");
			filter_results();
		});
	})
	function filter_results() {
		var $showNoResults = true;
		$filterData = [];
		$filterValue = [];
		$selectCounter = 0;
		$('.mobile_de_ergebnis').parent('div[class*="col-"]').show();
		for ($select = 0; $select < $('main .vehicle_filter .wrapper_select').length; $select++) {
			if ($('main .vehicle_filter .wrapper_select').eq($select).find('select').val() !== "-1") {
				$filterData[$selectCounter] = $('main .vehicle_filter .wrapper_select').eq($select).find('select').attr('class');
				$filterValue[$selectCounter] = $('main .vehicle_filter .wrapper_select').eq($select).find('select').val();
				$selectCounter++;
			}
		}
		$('.mobile_de_ergebnis').parent('div[class*="col-"]').each(function() {
			$visibility = true;
			for ($filter = 0; $filter < $filterData.length; $filter++) {
				// Preisgrenzen berechnen
				if ($filterData[$filter] == "data-price" || $filterData[$filter] == "data-age") {
					console.log("test");
					if (parseFloat($(this).attr($filterData[$filter])) < parseFloat($filterValue[$filter])) {
						$visibility = $visibility;
					} else {
						$visibility = false;
					}
				} else {
					if ($(this).attr($filterData[$filter]) == $filterValue[$filter]) {
						$visibility = $visibility;
					} else {
						$visibility = false;
					}
				}
			}
			if ($visibility == true) {
				$(this).show();
				$showNoResults = false;
			} else {
				$(this).hide();
				$showNoResults = $showNoResults;
			}
		});
		if ($showNoResults == true) {
			$('#no-results').show();
		} else {
			$('#no-results').hide();
		}
	}

    // CLick on Submenu-Items on page unser-neubau
    $('.menu_bottom li a').on('click', function(event) {
        var $currentUrl = window.location.href;
    
        if ($currentUrl.includes("bau-blog")) {
            event.preventDefault();
            var $baseUrl = window.location.protocol + "//" + window.location.host;
            var $ueberUns = $('.menu_top .nav-child li:first-child a').attr('href');
            var $href = $baseUrl + $ueberUns + $(this).attr('href');
            window.location.href = $href;
        }
	})

    // Special class for main page 'bau-blog'
    if (window.location.href.includes("bau-blog/")) {
        document.body.classList.add("bau-blog-subpage");
    }
});

